<template>
    <maintenance-page
        page-title="Customer"
        page-icon="mdi-account-multiple"
        collection-name="customer"
        done-redirect-url="/auth/admin/customer?isHistory=true"
    />
</template>

<script>
import MaintenancePage from '../../../components/pages/Maintenance';

export default {
    name: 'CustomerDetail',
    components: { MaintenancePage },
};
</script>
